/**
 * Function to load deffered images and iframe's when they are in viewport
 *
 * @param {Object}              observer_options             Pass Intersection Observer options
 * @param {string}              data_attribute
 * @param {NodeListOf<Element>} img_to_defer    Default elements are with data-srcset attribute, you may pass any other selector if you like or multiple selectors
 */
function deffer_images( { observer_options = {}, data_attribute = 'srcset', img_to_defer = document.querySelectorAll( '[data-srcset]' ) } ) {
	const observer = new IntersectionObserver( entries => {
		for ( const entry of entries ) {
			if ( entry.isIntersecting ) {
				const item = entry.target;
				changeDataSourceToSource( item, data_attribute );

				observer.unobserve( item );
			}
		}
	}, observer_options );

	for ( const item of img_to_defer ) {
		if ( item.closest( '.splide' ) ) {
			changeDataSourceToSource( item, 'srcset' );
			continue;
		}

		observer.observe( item );
	}
}

/**
 * Change image/iframe data-src/srcset to src/srcset
 *
 * @param item
 * @param data_attribute
 */
function changeDataSourceToSource( item, data_attribute ) {
	const node_type = item.nodeName.toLowerCase();
	if ( node_type === 'iframe' ) {
		item.src = item.dataset[ data_attribute ];
	} else {
		item.srcset = item.dataset[ data_attribute ];
	}
}

/**
 * Function to load first image of the deffered carousel
 * and then load next image when slides are changing
 * As a parameter pass the carousel that have slides with deffered images
 *
 * @requires jQuery
 * @deprecated from Bootstrap 5
 *
 * @param carousels {NodeListOf<Element>}
 */
function deffer_carousel( carousels = document.querySelectorAll( '.carousel.defer' ) ) {
	// Load deffered images in carousel
	// Get carousels with class defer, works only on
	// bootstrap carousel event when slide starts changing

	// fill srcset of first image from every carousel on page
	document.querySelectorAll( '.carousel.defer [data-srcset]' ).forEach( image => image.srcset = image.dataset.srcset );
	// fill srcset for deffered images in carousel on carousel slide change
	carousels.forEach( carousel => {
		$( carousel ).on( 'slide.bs.carousel', e => {
			if ( e.to === 0 ) {
				return;
			}

			const target_slide        = e.relatedTarget,
			      // next slide is div.carousel-item so we need to select img and source element's in that element
			      current_slide_image = target_slide.querySelectorAll( '[data-src]' );

			current_slide_image.forEach( image => {
				image.srcset = image.dataset.src;
				// image.classList.add('fade-in');
			} );
		} );
	} );
}

/**
 * Selects all elements that have .bg-deffer class, and also every element need's
 * data attribute where is the url to the image.
 * If there is only one size, then you may pass bool true so function can skip image size checking
 * Available attributes are data-xl, data-lg, data-md, data-sm, data-xs
 * Screen sizes are bootstrap breakpoints:  data-xl min-width 1200px
 *                                          data-lg min-width 992px
 *                                          data-md min-width 768px
 *                                          data-sm min-width 576px
 *                                          data-xs max-width 575px
 *
 * @param oneSize {boolean}                    Check if there is only one size of bg image
 * @param observerOptions {Object}             Pass Intersection Observer options
 * @param bg_to_defer {NodeListOf<Element>}    Default elements are with class .bg-deffer, you may pass any other selector if you like or multiple selectors
 */
function deffer_background( oneSize = false, observerOptions = {}, bg_to_defer = document.querySelectorAll( '.bg-deffer' ) ) {
	const observer = new IntersectionObserver( entries => {
		for ( const entry of entries ) {
			if ( entry.isIntersecting ) {
				const lazy_item  = entry.target;
				const image_size = !oneSize ? bg_image_width_exists( window.innerWidth, lazy_item.dataset ) : 'xl';

				if ( !lazy_item.dataset[ image_size ] ) {
					return;
				}

				lazy_item.style.backgroundImage = `url(${ lazy_item.dataset[ image_size ] })`;
				observer.unobserve( lazy_item );
			}
		}
	}, observerOptions );

	for ( const item of bg_to_defer ) {
		observer.observe( item );
	}
}

/**
 * Check if item has required dataset image otherwise get dataset with bigger image
 *
 * @param {number} window_width
 * @param {Object} dataset_object
 * @returns {string}
 */
function bg_image_width_exists( window_width, dataset_object ) {
	let image_size = 'xl';

	if ( window_width > 1199 ) {
		return image_size;
	}

	if ( window_width <= 575 ) {
		if ( dataset_object.hasOwnProperty( 'xs' ) ) {
			image_size = 'xs';
		} else {
			image_size = bg_image_width_exists( 767, dataset_object );
		}
	}

	if ( window_width > 575 && window_width < 768 ) {
		if ( dataset_object.hasOwnProperty( 'sm' ) ) {
			image_size = 'sm';
		} else {
			image_size = bg_image_width_exists( 991, dataset_object );
		}
	}
	if ( window_width > 767 && window_width < 992 ) {
		if ( dataset_object.hasOwnProperty( 'md' ) ) {
			image_size = 'md';
		} else {
			image_size = bg_image_width_exists( 1199, dataset_object );
		}
	}
	if ( window_width > 991 && window_width < 1200 ) {
		if ( dataset_object.hasOwnProperty( 'lg' ) ) {
			image_size = 'lg';
		} else {
			image_size = bg_image_width_exists( 1200, dataset_object );
		}
	}
	return image_size;
}

export {
	deffer_background,
	deffer_carousel,
	deffer_images
};
