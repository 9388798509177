/**
 * Class for setting and getting cookies from document
 */
export class Cookie {
	/**
	 *
	 *  cookieContainer {Element | string}
	 *  cookieName {string}
	 *  cookieValue {string}
	 *  cookieExpire {number} days
	 *  callback {boolean | Function}
	 */
	constructor( { cookieName = 'Cookie', cookieValue = 'accepted', cookieContainer = '.cookie', cookieExpire = 1, callback = false } ) {
		this.cookieContainer = cookieContainer instanceof Element ? cookieContainer : (cookieContainer ? document.querySelector( cookieContainer ) : '');
		this.cookieName      = cookieName;
		this.cookieValue     = cookieValue;
		this.cookieExpire    = cookieExpire;
		this.callback        = callback;
	}


	/**
	 * Show cookie if there is no saved on users pc
	 */
	init() {
		// Check if there is cookie container
		if ( !this.cookieContainer ) {
			return;
		}

		// Check if cookie already accepted
		if ( getCookie( this.cookieName ) ) return false;
		showCookie(
			{ cookieName: this.cookieName, cookieValue: this.cookieValue, cookieTime: this.cookieExpire },
			this.cookieContainer,
			'.btn--accept',
			this.callback
		);
	}
}

/**
 * Calculate expire time for cookie in days
 * @returns {string}
 */
function calculateTime( cookieExpire ) {
	const date_obj     = new Date(),
	      // Calculate expire days in milliseconds
	      expire       = cookieExpire * 24 * 60 * 60 * 1000,
	      // Get time that cookie was initialized
	      current_time = date_obj.getTime();

	// Set new time (expire time) for cookie
	date_obj.setTime( current_time + expire );

	// Return calculated date in UTC String
	return date_obj.toUTCString();
}

/**
 * Set cookie
 * @returns {string}
 */
function setCookie( { cookieName, cookieValue, cookieTime } ) {
	return document.cookie = `${ cookieName }=${ cookieValue };expires=${ calculateTime( cookieTime ) };path=/`;
}

/**
 * Get searched cookie
 * @param name {string}
 * @returns {string[]}
 */
function getCookie( name ) {
	const all_cookies     = getCookies(),
	      searched_cookie = all_cookies.filter( cookie => {
		      const cookie_arr = cookie.split( '=' );
		      return cookie_arr[ 0 ].trim() === name ? { name: cookie_arr[ 0 ], value: cookie_arr[ 1 ] } : false;
	      } );

	return searched_cookie.length ? searched_cookie : false;
}

/**
 * Get all cookies
 * @returns {string[]}
 */
function getCookies() {
	return document.cookie.split( ';' );
}

/**
 * Show cookie container
 */
function showCookie( { cookieName, cookieValue, cookieTime }, cookieContainer, btnAccept, callback = null ) {
	if ( !cookieContainer ) {
		console.warn( 'Cannot find cookie container: ', cookieContainer );
		return;
	}
	const btn = btnAccept instanceof Element ? btnAccept : document.querySelector( btnAccept );
	cookieContainer.classList.add( 'show' );

	// Attach click event on accept button
	btn.addEventListener( 'click', () => {
		cookieContainer.classList.remove( 'show' );
		setCookie( { cookieName, cookieValue, cookieTime } );

		// Get callback if any provided
		if ( callback && callback instanceof Function ) {
			callback( { setCookie, cookieContainer, btnAccept } );
			return;
		}

		setCookie( { cookieName, cookieValue, cookieTime } );
	} );
}

export {
	calculateTime,
	setCookie,
	getCookies,
	getCookie,
	showCookie
}
