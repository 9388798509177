import { getCookie, setCookie } from './cookie';

export class Product {
	cookie;
	elements;
	#is_mobile;
	#routes;
	#nonce;

	constructor() {
		this.cookie     = {
			setCookie,
			getCookie
		};
		this.elements   = {
			compare_table_wrapper:  document.getElementById( 'products-to-compare' ) || false,
			compare_count:          document.querySelectorAll( '.product-compare-count' ),
			wishlist_table_wrapper: document.getElementById( 'products-wishlist' ),
			wishlist_header_link:   document.getElementById( 'wishlist-link' )
		};
		this.#routes    = {
			wishlist: '/wp-json/custom/add-to-wishlist',
			compare:  '/wp-json/custom/add-to-compare'
		};
		this.#nonce     = document.querySelector( '[data-nonce]' )?.dataset.nonce || '';
		this.#is_mobile = window.matchMedia( '(max-width:991px)' ).matches;

		this.addToWishlist();
		this.removeFromWishlist();

		this.addToCompare();
		this.removeFromCompare();
		this.checkFavoriteProducts();
	}

	addToWishlist() {
		document.documentElement.delegateEventListener( 'click', '.btn--favorite', ( e, btn ) => {
			if ( btn.dataset.login ) {
				window.location = btn.dataset.login + '?login_required';
				return;
			}
			btn.classList.add( 'activating' );
			this.modifyProductsWishlist( btn );
		} );
	}

	addToCompare() {
		document.documentElement.delegateEventListener( 'click', '.btn--compare', ( e, btn ) => {
			this.notifyUserWithText( btn );
			btn.classList.add( 'active' );
			this.saveProducts( 'compare', btn.dataset.product_id, 30 );
		} );
	}

	/**
	 *
	 * @param cookie_name
	 * @param product_id
	 * @param cookie_time
	 * @param max_products
	 */
	saveProducts( cookie_name, product_id, cookie_time, max_products = 3 ) {
		const old_saved_products = this.cookie.getCookie( cookie_name );
		let saved_products       = [];

		if ( old_saved_products ) {
			saved_products = old_saved_products[ 0 ].split( '=' )[ 1 ].split( ',' );

			if ( !saved_products.includes( product_id ) ) {
				saved_products.push( product_id );
			}
		} else {
			saved_products = [ product_id ];
		}

		// Check if there are more than max allowed products, if so then remove first item from array
		// if ( max_products > 0 && saved_products.length > max_products ) {
		// 	saved_products.shift();
		// }

		if ( cookie_name === 'wishlist' ) {
			this.modifyProductsWishlist();
		} else {
			this.getProductsToCompare( saved_products.join( ',' ) );
		}

		this.cookie.setCookie( { cookieName: cookie_name, cookieValue: saved_products.join( ',' ), cookieTime: cookie_time } );
	}

	/**
	 *
	 */
	removeFromCompare() {
		document.documentElement.delegateEventListener( 'click', '.btn--remove-compare', ( e, btn ) => {
			const { removed, saved } = this.removeProductFromCookies( btn.dataset.product_id, 'compare', 30 );
			btn.classList.add( 'loading' );

			if ( removed ) {
				this.getProductsToCompare( saved.join( ',' ), this.elements.compare_table_wrapper, btn );
			}
		} );
	}

	/**
	 * Remove product from wishlist when pressed trashcan on page wishlist
	 */
	removeFromWishlist() {
		document.documentElement.delegateEventListener( 'click', '.btn--remove-wishlist', ( e, btn ) => {
			this.modifyProductsWishlist( btn, 1 );
		} );
	}

	/**
	 *
	 * @param product_id
	 * @param cookie_name
	 * @param cookie_time
	 * @return {boolean|Object}
	 */
	removeProductFromCookies( product_id, cookie_name, cookie_time ) {
		let saved_products = this.cookie.getCookie( cookie_name );

		if ( !saved_products ) {
			return false;
		}

		saved_products        = saved_products[ 0 ].split( '=' )[ 1 ].split( ',' );
		const removed_product = saved_products.splice( saved_products.indexOf( product_id ), 1 );

		this.cookie.setCookie( { cookieName: cookie_name, cookieValue: saved_products.join( ',' ), cookieTime: saved_products.length === 0 ? -1 : cookie_time } );

		return { removed: removed_product, saved: saved_products };
	}

	/**
	 * Show text on button for response
	 *
	 * @param btn
	 */
	notifyUserWithText( btn ) {
		const btn_text = btn.innerHTML;
		btn.innerHTML  = btn.dataset.added_text;

		setTimeout( () => btn.innerHTML = btn_text, 3500 );
	}

	/**
	 *
	 * @param {String} product_ids
	 * @param update_table
	 * @param {HTMLButtonElement} active_btn
	 */
	getProductsToCompare( product_ids, update_table = false, active_btn = null ) {
		const compare_popup = document.getElementById( 'compare' );

		fetch( `${ this.#routes.compare }?product_ids=${ product_ids }&update_table=${ update_table ? 1 : 0 }` )
			.then( res => res.json() )
			.then( data => {
				if ( data.error ) {
					console.error( data.error );
					return;
				}

				if ( compare_popup ) {
					compare_popup.innerHTML = data.products;
				}
				this.elements.compare_table_wrapper && ( this.elements.compare_table_wrapper.innerHTML = data.table );
				// Update all product counts on page
				this.elements.compare_count && ( this.elements.compare_count.forEach( item => item.textContent = data.product_count ) );

				// Show popup only if user is not on page for comparing
				!update_table && compare_popup?.classList.add( 'show' );
			} )
			.catch( err => console.error( err ) )
			.finally( () => active_btn?.classList.remove( 'loading' ) );
	}

	/**
	 * Update acf wishlist field
	 *
	 * @param btn
	 * @param update_table
	 */
	modifyProductsWishlist( btn, update_table = 0 ) {
		const wishlist_popup = document.getElementById( 'wishlist' );

		fetch( `${ this.#routes.wishlist }`, {
			method:      'post',
			headers:     {
				'Content-Type': 'application/json',
				'X-WP-Nonce':   this.#nonce
			},
			credentials: 'same-origin',
			body:        JSON.stringify(
				{
					update_table,
					product_id: btn.dataset.product_id,
				} )
		} )
			.then( res => res.json() )
			.then( data => {
				if ( data.error ) {
					console.error( data.error );
					return;
				}

				if ( wishlist_popup && !this.#is_mobile ) {
					wishlist_popup.innerHTML = data.products;

					// Add/remove class on header wishlist link for showing/hiding dot
					if ( data.product_count === 0 ) {
						this.elements.wishlist_header_link.classList.remove( 'active' );
					} else {
						this.elements.wishlist_header_link.classList.add( 'active' );
					}

					this.elements.wishlist_header_link.children[ 1 ].textContent = `(${ data.product_count })`;
					!update_table && wishlist_popup.classList.add( 'show' );
				}

				if ( data.updated_field ) {
					if ( !data.remove ) {
						btn.classList.add( 'active' );
					} else {
						btn.classList.remove( 'active' );
					}

					btn.classList.remove( 'activating' );
				}
				this.elements.wishlist_table_wrapper && ( this.elements.wishlist_table_wrapper.innerHTML = data.table );
			} )
			.catch( err => console.error( err ) );
	}

	/**
	 * Check for products in wishlist on page load
	 */
	checkFavoriteProducts() {
		// Load only if there are wishlist buttons
		if ( !document.querySelector( '.btn--favorite[data-product_id]' ) || document.querySelector( '[data-login]' ) ) return;

		fetch( this.#routes.wishlist, {
			method:      'post',
			headers:     {
				'Content-Type': 'application/json',
				'X-WP-Nonce':   this.#nonce
			},
			credentials: 'same-origin',
			body:        JSON.stringify(
				{
					check_favorite: true,
				} )
		} )
			.then( res => res.json() )
			.then( data => {
				const { product_ids } = data;

				if ( product_ids ) {
					for ( const id of product_ids ) {
						const element = document.querySelector( `.btn--favorite[data-product_id="${ id }"]` );
						element && element.classList.add( 'active' );
					}
				}
			} )
			.catch( err => console.error( err ) );
	}
}
