/**
 * Returns random number from provided min and max values
 * @param min {number}
 * @param max {number}
 * @returns {number}
 */
function RandomNumbers( min, max ) {
	min = Math.ceil( min );
	max = Math.floor( max );
	return Math.floor( Math.random() * ( max - min + 1 ) ) + min;
}

/**
 * Showing element with css class or jquery methods fadeIn and slideIn
 *
 * @param element {Element}             HTML Element from Document
 * @param InputToFocus {Element|String} Pass HTML element or selector
 * @param elClass {string}              Class to control, default is show
 */
function ShowElement( element, InputToFocus = '', elClass = 'show' ) {
	element.setAttribute( 'aria-expanded', 'true' );
// Get element to open from btn's data-open attribute
	const target_element    = element.dataset.open ? document.querySelector( element.dataset.open ) : undefined,
	      type_of_animation = element.dataset.hasOwnProperty( 'function' ) ? element.dataset.function : null;

	if ( target_element == undefined ) {
		console.warn( 'Cannot find target element: ', target_element );
		return;
	}

	// If element to open has class modal, add class overflow to body
	// Overflow class is used in css to apply overflow hidden on body
	// to prevent scrolling while modal is openned
	if ( target_element.classList.contains( 'modal' ) ) {
		document.body.classList.add( 'overflow' );
	}
	switch ( type_of_animation ) {
		case 'fade':
			$( target_element ).fadeIn();
			break;
		case 'slide':
			target_element.slideDown( 'show' );
			break;
		default:
			target_element.classList.add( elClass );
	}

	// Focus on input after openning modal if provided
	if ( InputToFocus ) {
		const input_el = InputToFocus instanceof HTMLElement ? InputToFocus : target_element.querySelector( InputToFocus );
		input_el && input_el.focus();
	}
	window.toggled_btn && window.toggled_btn.setAttribute( 'aria-expanded', 'false' );
	window.toggled_btn = element;
	// window.showed_item && window.showed_item.classList.remove( elClass );
	// window.showed_item = target_element;
	new Emmit( target_element, { type: 'show', data: { btn: element } } );
}

/**
 * Hiding element with css class or jquery methods fadeIn and slideIn
 *
 * @param element {Element}     HTML Element from Document
 * @param elClass {string}      Class to control, default is show
 */
function HideElement( element, elClass = 'show' ) {
	element.setAttribute( 'aria-expanded', 'false' );
	// Get element to open from btn's data-open attribute
	const target_element    = element.classList.contains( 'modal' ) || element.classList.contains( 'navbar-collapse' ) ? element :
	                          ( element.dataset.close ? document.querySelector( element.dataset.close ) : undefined ),
	      type_of_animation = element.dataset.hasOwnProperty( 'function' ) ? element.dataset.function : null;

	if ( target_element == undefined ) {
		console.warn( 'Cannot find target element: ', target_element );
		return;
	}

	// If element to open has class modal, add class overflow to body
	// Overflow class is used in css to apply overflow hidden on body
	// to prevent scrolling while modal is openned
	if ( target_element.classList.contains( 'modal' ) ) {
		document.body.classList.remove( 'overflow' );
	}
	switch ( type_of_animation ) {
		case 'fade':
			$( target_element ).fadeOut();
			break;
		case 'slide':
			target_element.slideUp( 'show' );
			break;
		default:
			target_element.classList.remove( elClass );
	}
	window.toggled_btn && window.toggled_btn.setAttribute( 'aria-expanded', 'false' );
	window.toggled_btn = undefined;
	// window.showed_item && window.showed_item.classList.remove( elClass );
	// window.showed_item = undefined;
	new Emmit( target_element, { type: 'hide', data: { btn: element } } );
}

/**
 * Toggles element with css class or jquery methods fadeIn and slideIn
 *
 * @param element {Element}     HTML Element from Document
 * @param elClass {string}      Class to control, default is show
 */
function ToggleElement( element, elClass = 'show' ) {
	element.setAttribute( 'aria-expanded', element.getAttribute( 'aria-expanded' ) === 'true' ? 'false' : 'true' );
	// Get element to open from btn's data-open attribute
	const target_element    = element.dataset.togle ? document.querySelector( element.dataset.togle ) : undefined,
	      type_of_animation = element.dataset.hasOwnProperty( 'function' ) ? element.dataset.function : null;

	if ( target_element == undefined ) {
		console.warn( 'Cannot find target element: ', target_element );
		return;
	}

	// If element to open has class modal, add class overflow to body
	// Overflow class is used in css to apply overflow hidden on body
	// to prevent scrolling while modal is openned
	if ( target_element.classList.contains( 'modal' ) ) {
		document.body.classList.toggle( 'overflow' );
	}
	switch ( type_of_animation ) {
		case 'fade':
			$( target_element ).fadeToggle();
			break;
		case 'slide':
			target_element.slideToggle( 'show' );
			break;
		default:
			target_element.classList.toggle( elClass );
	}

	new Emmit( target_element, { type: 'toggle', data: { btn: element } } );
}

/**
 * Custom collapse function. Closes all siblings when one is clicked and toggles clicked one if necessery
 * Layout for Collapse
 *
 <div id="accordion">
 <div class="collapsible">
 <button class="btn active" data-collapse="#collapseOne" data-parent="#accordion">Element 1</button>
 <div id="collapseOne" class="collapse show">

 </div>
 </div>
 <div class="collapsible">
 <button class="btn" data-collapse="#collapseTwo" data-parent="#accordion">Element 2</button>
 <div id="collapseTwo" class="collapse">

 </div>
 </div>
 </div>
 *
 */
function Collapsible() {
	document.addEventListener( 'click', e => {
		if ( !e.target.closest( '[data-collapse]' ) ) return;
		const closest_el        = e.target.closest( '[data-collapse]' ),
		      parent_el         = document.querySelector( closest_el.dataset.parent ),
		      current_target_el = parent_el.querySelector( closest_el.dataset.collapse ),
		      btn_collapses     = parent_el.querySelectorAll( '[data-collapse]' );

		btn_collapses.forEach( btn => {
			const other_target_el = parent_el.querySelector( btn.dataset.collapse );

			// If it's the same btn than toggle elements class, otherwise remove class show
			if ( btn.classList === closest_el.classList ) {
				current_target_el.classList.toggle( 'show' );
				closest_el.classList.toggle( 'active' );
			} else {
				other_target_el.classList.remove( 'show' );
				btn.classList.remove( 'active' );
			}

			// Control every collapsible height
			new HeightAnim( other_target_el );
		} );

	} );
}

/**
 * Scroll element up or down based on attributes
 *
 * @param item_to_scroll
 * @param direction
 */
function ScrollItem( item_to_scroll, direction ) {
	let amount_to_scroll_top  = item_to_scroll.scrollTop,
	    amount_to_scroll_left = item_to_scroll.scrollLeft;

	switch ( direction ) {
		case 'up':
			amount_to_scroll_top += 100;
			break;
		case 'down':
			amount_to_scroll_top -= 100;
			break;
		case 'left':
			amount_to_scroll_left -= 100;
			break;
		case 'right':
			amount_to_scroll_left += 100;
			break;
		default:
			amount_to_scroll_left += 100;
	}

	item_to_scroll.scroll( {
		                       top:      amount_to_scroll_top,
		                       left:     amount_to_scroll_left,
		                       behavior: 'smooth'
	                       } );
}

/**
 * Add class on header when document is scrolled pass header height.
 * You can pass optional callback function that receives header element as parameter
 *
 * @param header {Element | string}
 * @param callback {Function | boolean}
 */
function HeaderControl( header, callback = false ) {
	const header_el     = header instanceof Element ? header : document.querySelector( header ),
	      header_height = header_el.offsetHeight;
	let direction       = null;

	if ( window.scrollY >= header_height ) {
		header_el.classList.add( 'scrolled' );
		direction = 'down';
	} else {
		header_el.classList.remove( 'scrolled' );
		direction = 'up';
	}

	if ( callback ) {
		callback( header_el, direction );
	}

	window.requestAnimationFrame( () => {
		HeaderControl( header_el, callback );
	} );
}

/**
 * Animate elements height to original
 * @param element
 * @param classCheck {string}
 * @constructor
 */
function HeightAnim( element, classCheck = 'show' ) {
	element.style.height = 'auto';
	const item_height    = element.classList.contains( classCheck ) ? element.offsetHeight : 0;
	element.style.height = '0px';
	setTimeout( () => {
		element.style.height = item_height + 'px';
	}, 50 );
}

/**
 * Remove autofill from forms on page
 * @param forms
 * @constructor
 */
function RemoveAutoFill( forms ) {
	if ( !forms ) {
		return;
	}

	if ( forms instanceof HTMLElement ) {
		forms.setAttribute( 'autocomplete', 'off' );
		return;
	}

	for ( const form of forms ) {
		form.setAttribute( 'autocomplete', 'off' );
	}
}

/**
 * Create form data from form elements
 * @param form_elements
 * @returns {FormData}
 * @constructor
 */
function CreateFormData( form_elements ) {
	// Make form data object and populate it with all inputs from form
	let data = new FormData();
	for ( const el of form_elements ) {
		if ( el.type === 'file' ) {
			data.append( el.name, el.files[ 0 ] );
		} else if ( el.value !== '' ) {
			data.append( el.name, el.value );
		}
	}

	return data;
}

/**
 * Function to get document current language code from html attribute lang
 * @returns {string} 'en', 'sr'
 * @constructor
 */
function GetDocumentLang() {
	return document.documentElement.lang.split( '-' )[ 0 ];
}

/**
 * Emits custom event
 *
 * @param element {Document, Element, string} - default document
 * @param type
 * @param bubbles
 * @param cancelable
 * @param data
 * @constructor
 */
function Emmit( element, { type, bubbles = true, cancelable = false, data = null } ) {
	if ( Element.prototype.hasOwnProperty( 'emmit' ) ) {
		return element.emmit( type, bubbles, cancelable, data );
	}

	const event = document.createEvent( 'HTMLEvents' ),
	      el    = element === '' ? document : element instanceof Element ? element : document.querySelector( element );
	if ( !el ) {
		console.warn( 'Cannot find element', element );
		return;
	}
	event.initEvent( type, bubbles, cancelable );
	el.dispatchEvent( event );
}

/**
 *
 * Controls anchor links or buttons in content for scrolling to element
 * Anchor needs to have anchor class and hash to the element
 * Button needs to have data-scroll attribute
 *
 * @param selector {string}
 * @param correction {number}
 * @constructor
 */
function AnchorScroll( selector, correction = 0 ) {
	this.selector   = selector;
	this.correction = correction;

	if ( !this.selector ) {
		console.warn( 'You must provide a selector!' );
		return;
	}

	document.addEventListener( 'click', e => {
		if ( !e.target.closest( this.selector ) ) return;

		const closest_el = e.target.closest( this.selector );
		let target_element_selector;

		if ( closest_el.tagName === 'A' ) {
			e.preventDefault();
			target_element_selector = closest_el.hash;
		} else {
			target_element_selector = closest_el.dataset.scroll;
		}


		const target_el = document.querySelector( target_element_selector );

		if ( !target_el ) {
			return;
		}

		setTimeout( () => {
			window.scroll( {
				               top:      Offset( target_el ).top - this.correction,
				               behavior: 'smooth'
			               } );
		}, 50 );
	} );
}

/**
 * Scroll to top
 * Controls showing button and handles click event to scroll to top
 *
 */
class ToTop {
	/**
	 * @param btn {string, Element} - HTML button element
	 * @param amount_to_scroll {number} - amount to scroll in pixels
	 */
	constructor( btn, amount_to_scroll = 800 ) {
		this.btn              = btn instanceof Element ? btn : document.querySelector( btn );
		this.amount_to_scroll = amount_to_scroll;

		if ( !this.btn ) {
			console.warn( `Cannot find or invalid selector for btn: '${ btn }'` );
			return;
		}

		this.showToTop();
		this.attachClick();
	}

	/**
	 * Checks if document is scrolled enough to show btn
	 */
	showToTop() {
		window.pageYOffset > this.amount_to_scroll ? this.btn.classList.add( 'show' ) : this.btn.classList.remove( 'show' );

		requestAnimationFrame( () => {
			this.showToTop( this.btn, this.amount_to_scroll );
		} );
	}

	/**
	 * Attaches click event on button
	 */
	attachClick() {
		this.btn.addEventListener( 'click', this.scrollToTop );
	}

	scrollToTop() {
		window.scroll( {
			               top:      0,
			               behavior: 'smooth'
		               } );
	}
}

/**
 * Get elements position relative to document
 *
 * @param el
 * @returns {{top: *, left: *}}
 * @constructor
 */
function Offset( el ) {
	const rect       = el.getBoundingClientRect(),
	      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
	      scrollTop  = window.pageYOffset || document.documentElement.scrollTop;
	return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

/**
 *
 * @param {Object} parameters_obj
 *
 * @return {String}
 */
function composeParameters( parameters_obj ) {
	return Object.entries( parameters_obj ).map( parameter => {
		return `${ parameter[ 0 ] }=${ parameter[ 1 ] }`;
	} ).join( '&' );
}

/**
 * Generate random id
 *
 * @param prefix
 * @return {string}
 */
function generateId( prefix = '_' ) {
	return prefix + Math.random().toString( 36 ).slice( 2 );
}

/**
 * Generate close button
 *
 * @param {String} targetElement ID/Class of the element
 * @param {String} classes
 * @return {string}
 */
function closeBtn( targetElement, classes = '' ) {
	return `<button type="button" class="btn btn--close ${ classes }" data-close="${ targetElement }" aria-controls="${ targetElement }" aria-expanded="false">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="20" height="20">
			<path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176
			189.28 75.93 89.21 c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28
			32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28
	        12.28-32.19 0-44.48L242.72 256z" /></svg></button>`;
}

export {
	RandomNumbers,
	ShowElement,
	HideElement,
	ToggleElement,
	Collapsible,
	ScrollItem,
	HeaderControl,
	composeParameters,
	generateId,
	closeBtn,
	Emmit,
	ToTop,
	HeightAnim,
	RemoveAutoFill,
	CreateFormData,
	GetDocumentLang,
	AnchorScroll,
	Offset
};
