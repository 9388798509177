import { getCookie, setCookie } from './cookie';

/**
 *
 * @param {number} wait_time in seconds
 * @param {number} cookie_save_time in days
 */
export function newsletterPopup( wait_time, cookie_save_time ) {
	const current_time      = new Date(),
	      default_settings  = {
		      subscribed:     false,
		      closed:         false,
		      date:           current_time.getTime(),
		      date_next_show: current_time.getTime()
	      },
	      storage_name      = 'newsletter-Audiovox',
	      newsletter_cookie = getCookieValueAsObject( storage_name ) || default_settings,
	      storage           = getLocalStorage( storage_name ) || default_settings,
	      newsletter_modal  = document.querySelector( '.modal--newsletter' );

	if ( !newsletter_modal ) {
		return;
	}

	const subscribed   = storage?.subscribed || newsletter_cookie?.subscribed,
	      date_to_show = storage?.date_next_show || newsletter_cookie?.date_next_show;

	if ( subscribed || ( date_to_show > current_time.getTime() ) ) {
		return;
	}

	setTimeout( () => {
		newsletter_modal && newsletter_modal.classList.add( 'show' );
		document.body.classList.add( 'overflow' );
	}, wait_time * 1000 );

	document.documentElement.delegateEventListener( 'click', '.modal--newsletter .btn--submit', () => {
		storage.closed = true;

		setLocalStorage( storage_name, storage );
		setCookie( { cookieName: storage_name, cookieValue: JSON.stringify( storage ), cookieTime: cookie_save_time } );
	} );

	document.documentElement.delegateEventListener( 'hide', '.modal--newsletter', () => {
		storage.subscribed     = false;
		storage.closed         = true;
		storage.date_next_show = new Date( current_time.getTime() + calculateTimeInDays( cookie_save_time ) ).getTime();

		setLocalStorage( storage_name, storage );
		setCookie( { cookieName: storage_name, cookieValue: JSON.stringify( storage ), cookieTime: cookie_save_time } );
	} );
}

/**
 *
 * @param {number} days
 * @return {number}
 */
const calculateTimeInDays = days => days * 24 * 60 * 60 * 1000;

function getCookieValueAsObject( item ) {
	const data = getCookie( item );

	if ( !data ) {
		return false;
	}

	return JSON.parse( data[ 0 ].split( '=' )[ 1 ] );
}

/**
 *
 * @param item
 * @param value
 *
 * @return {string}
 */
function setLocalStorage( item, value = {} ) {
	const data = JSON.stringify( value );

	localStorage.setItem( item, data );

	return data;
}

/**
 *
 * @param item
 * @return {Object}
 */
function getLocalStorage( item ) {
	const data = localStorage.getItem( item );

	return data ? JSON.parse( data ) : false;
}

/**
 * Handle subscribing to newsletter
 *
 * @param cookie_save_time
 */
export function subscribeToNewsletter( cookie_save_time ) {
	document.documentElement.delegateEventListener( 'submit', '.newsletter-form', ( e, form ) => {
		e.preventDefault();

		form.elements[ 'email' ].classList.remove( 'invalid' );
		const form_response = form.querySelector( '.form-response' ),
		      storage       = getLocalStorage( 'newsletter-Audiovox' ) || {};
		form_response.classList.remove( 'form-response--error' );

		if ( form.elements[ 'email' ]?.value === '' ) {
			form.elements[ 'email' ].classList.add( 'invalid' );
			return;
		}

		const data = new FormData( form );
		form.elements[ 'subscribe' ].classList.add( 'loading' );
		form_response.textContent = '';

		fetch( '/wp-json/custom/subscribe', {
			method: 'post',
			body:   data
		} )
			.then( async res => {
				const response = await res.json();

				return [ response, res.status ];
			} )
			.then( ( [ response, status ] ) => {
				console.log( response, status );
				if ( status !== 200 ) {
					form_response.classList.add( 'form-response--error' );

					if ( response.message.includes( 'Već ste prijavljeni' ) ) {
						storage.subscribed = true;
					} else {
						storage.failed = true;
					}
				} else if ( status === 200 ) {
					form.elements[ 'email' ].value = '';
					storage.subscribed             = true;
				}

				form_response.textContent = response.message;
				setLocalStorage( 'newsletter-Audiovox', storage );
				setCookie( { cookieName: 'newsletter-Audiovox', cookieValue: JSON.stringify( storage ), cookieTime: cookie_save_time } );
			} )
			.catch( err => console.error( err ) )
			.finally( () => form.elements[ 'subscribe' ].classList.remove( 'loading' ) );
	} );
}
