import { Splide }                                                                 from '@splidejs/splide';
import AddToCart                                                                  from './AddToCart';
import App                                                                        from './App';
import { carouselInit }                                                           from './carousel';
import { Cookie }                                                                 from './cookie';
import { deffer_images }                                                          from './img-defer';
import { subscribeToNewsletter, newsletterPopup }                                 from './newsletter';
import { Product }                                                                from './Product';
import Search                                                                     from './Search';
import { HideElement, Offset, RemoveAutoFill, ShowElement, ToggleElement, ToTop } from './utils';

window.addEventListener( 'load', () => {
	const app    = new App( 'Audiovox' ),
	      header = document.querySelector( '#header' );
	app.setData( 'header', header );

	window.requestAnimationFrame( () => {
		updateCssVariables( header );
	} );

	// Import quantity control module
	if ( document.querySelector( '.btn--qty' ) ) {
		import('./quantity-control')
			.then( ( quantity_control ) => {
				new quantity_control.default().init();
			} )
			.catch( err => console.error( err ) );
	}

	// Init custom select if present any on page
	const select_inputs = document.querySelectorAll( 'select' );
	if ( select_inputs.length ) {
		import('./CustomSelect')
			.then( module => {
				new module.default( select_inputs ).init();
			} );
	}


	// Import header js on mobile
	if ( window.matchMedia( '(max-width: 991px)' ).matches ) {
		import('./header')
			.then( ( { Header } ) => {
				Header();
			} );
		const search_wrapper = header.querySelector( '.search-form-wrapper' );
		search_wrapper.classList.add( 'modal' );
	}

	if ( window.matchMedia( '(min-width: 992px)' ).matches ) {
		import('./StickyHeader')
			.then( ( { StickyHeader } ) => {
				StickyHeader();
			} );
	}

	deffer_images( { observer_options: { rootMargin: '0px 0px 150px 0px' } } );

	// Init bootstrap carousel if any carousel is present
	carouselInit();

	document.addEventListener( 'click', e => {
		if ( !e.target.closest( '[data-open]' ) ) return;
		ShowElement( e.target.closest( '[data-open]' ) );
	} );

	document.addEventListener( 'click', e => {
		const openned_modal = document.querySelector( '.modal.show' );
		if ( e.target.closest( '[data-close]' ) ) {
			HideElement( e.target.closest( '[data-close]' ) );
		} else if ( e.target == openned_modal ) {
			HideElement( openned_modal );
		}
	} );

	document.addEventListener( 'click', e => {
		if ( !e.target.closest( '[data-togle]' ) ) return;
		ToggleElement( e.target.closest( '[data-togle]' ) );
	} );

	if ( window.matchMedia( '(min-width:992px)' ).matches ) {
		// Add padding to modal for secondary nav for alignment with navbar toggler
		document.addEventListener( 'toggle', e => {
			if ( e.detail.btn.dataset.togle !== '#secondary-menu' ) return;

			const { btn, element }    = e.detail;
			element.style.paddingLeft = new Offset( btn ).left + 'px';
		} );
	}

	new RemoveAutoFill( document.querySelectorAll( 'form' ) );
	new Search( document.querySelector( '.search-form-wrapper' ) ).init();
	//new AddToCart().init();
	new Product();
	new ToTop( '.btn--top', window.innerHeight * 2 );
	new Cookie(
		{
			cookieName:   'Audiovox_cookie',
			cookieValue:  '1',
			cookieExpire: 30
		} ).init();

	newsletterPopup( 15, 14 );
	subscribeToNewsletter( 14 );

	const splide_sliders = document.querySelectorAll( '.splide' );

	if ( splide_sliders.length ) {
		Splide.defaults = {
			type: 'loop', perPage: 1, gap: 20, perMove: 1,
		}
	
				for ( const slider of splide_sliders ) {
					slider.splide = new Splide(slider, slider.dataset.options ? JSON.parse(slider.dataset.options) : {});
					const images = slider.querySelectorAll('img');

					console.log(slider.dataset.options);

					for (const image of images) {
						if (!image.dataset.srcset) continue;
		
						image.srcset = image.dataset.srcset;
					}
					slider.splide.mount();
				}
	}

	let element = document.querySelectorAll('.carousel-item');
	element[0].classList.add('active');

	
} );

function updateCssVariables( header ) {
	document.body.style.setProperty( '--header-height', `${ header.offsetHeight }px` );
	document.body.style.setProperty( '--viewport-height', `${ window.innerHeight }px` );

	window.requestAnimationFrame( () => {
		updateCssVariables( header );
	} );
}


