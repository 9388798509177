import inViewport from './inViewport';

/**
 * Init bootstrap 5 carousel with dynamic import
 *
 * @param carousels_items
 */
export function carouselInit( carousels_items = '.carousel' ) {
	const carousels = carousels_items instanceof HTMLElement ? carousels_items : document.querySelectorAll( carousels_items );
	if ( carousels.length ) {
		import('bootstrap')
			.then( ( { Carousel } ) => {
				for ( const carousel of carousels ) {
					const inited_carousel = new Carousel( carousel );
					inViewport( carousel, ( { entry, target_el } ) => {
						inited_carousel.cycle();
					}, {} );
				}
			} );
	}
}



