import { calculateTime, getCookie, getCookies, setCookie } from './cookie';

export default class App {
	app_name;
	app_version;
	cookie;
	#data;

	constructor( app_name, app_version = '1.0.0' ) {
		this.app_name    = app_name;
		this.app_version = app_version;
		this.cookie      = {
			getCookie,
			getCookies,
			setCookie,
			calculateTime
		};
		this.#data       = {};

		window[ app_name ] = this;

		// Init custom prototypes
		this.prototypes();
	}

	setData( key, value ) {
		this.#data[ key ] = value;
	}

	getData( key ) {
		return this.#data[ key ] ?? false;
	}

	prototypes() {
		/**
		 *
		 * @param addClass
		 * @return {Element}
		 */
		Element.prototype.slideDown = function ( addClass = '' ) {
			const current_el_height = this.offsetHeight;

			this.style.height = 'auto';
			const item_height = this.offsetHeight;
			this.style.height = current_el_height + 'px';
			setTimeout( () => {
				if ( addClass ) {
					this.classList.add( addClass );
				}

				this.style.height = item_height + 'px';
			}, 10 );

			return this;
		};

		Element.prototype.slideUp = function ( removeClass = '', minHeight = 0 ) {
			this.style.height = minHeight + 'px';
			if ( removeClass ) {
				this.classList.remove( removeClass );
			}

			return this;
		};

		Element.prototype.slideToggle = function ( toggleClass = '', minHeight = 0 ) {
			const current_height = this.offsetHeight;
			if ( current_height === 0 || current_height === minHeight ) {
				this.slideDown( toggleClass );
			} else {
				this.slideUp( toggleClass, minHeight );
			}

			return this;
		};

		/**
		 *
		 * @param type
		 * @param bubbles
		 * @param cancelable
		 * @param data
		 * @return {Element}
		 */
		Element.prototype.emmit = function ( type, bubbles = true, cancelable = false, data = null ) {
			const event = new CustomEvent( type, {
				bubbles,
				cancelable,
				detail: {
					element: this,
					...data
				}
			} );

			this.dispatchEvent( event );
			return this;
		};

		/**
		 *
		 * @param {string} type
		 * @param {String} selector
		 * @param {EventListener} listener
		 * @param {Object} listenerParameters
		 * @param {Object} options
		 * @return {Element}
		 */
		Element.prototype.delegateEventListener = function ( type, selector, listener, listenerParameters = null, options = false ) {
			this.addEventListener( type, e => {
				if ( selector instanceof Element && e.target === selector || !( selector instanceof Element ) && e.target.closest( selector ) ) {
					if ( listener instanceof Function ) {
						/**
						 * Pass parameters to callback function
						 *
						 * @param {Event} e
						 * @param {HTMLElement} clicked element
						 * @param               listenerParameters
						 */
						listener( e, e.target.closest( selector ), listenerParameters );
					}
				}
			}, options );

			return this;
		};
	}
}
