export default class Search {
	params;
	search_form_wrapper;
	search_submit_btn;
	search_input;
	search_results;
	products_output;
	categories_output;
	#url;
	#timeout;
	#wait_time;
	#min_chars;
	#results_empty;
	#hide_popup_timer;
	#hide_popup_timer_wait;
	#header_element;
	#input_focus;
	#popup_hovering;

	/**
	 *
	 * @param {Element|String} form_wrapper
	 * @param {Number} wait_time
	 * @param {Number} min_chars
	 */
	constructor( form_wrapper, wait_time = 300, min_chars = 3 ) {
		this.search_form_wrapper    = form_wrapper instanceof Element ? form_wrapper : document.querySelector( form_wrapper );
		this.search_input           = form_wrapper.querySelector( '#search-input' );
		this.search_submit_btn      = this.search_form_wrapper.querySelector( '.btn--search' );
		this.search_results         = form_wrapper.querySelector( '.search-results' );
		this.products_output        = this.search_results.querySelector( '.products-results' );
		this.categories_output      = this.search_results.querySelector( '.categories-results' );
		this.params                 = {};
		this.#url                   = '/wp-json/custom/search?';
		this.#timeout               = null;
		this.#wait_time             = wait_time;
		this.#min_chars             = min_chars;
		this.#results_empty         = true;
		this.#hide_popup_timer      = null;
		this.#hide_popup_timer_wait = 150;
		this.#header_element        = Audiovox.getData( 'header' ) ?? document.querySelector( '#header' );
		this.#input_focus           = false;
		this.#popup_hovering        = false;
	}

	/**
	 * Init search functionality
	 */
	init() {
		// User Typing into search input
		this.search_input.addEventListener( 'keyup', () => {
			// Remove timeout if user starts typing before request initializes and
			// set new timout for new request
			clearTimeout( this.#timeout );

			if ( this.search_input.value.length < this.#min_chars ) {
				return;
			}

			this.search_submit_btn.classList.add( 'loading' );
			// Compose parameters
			this.params = this.composeParameters( {
				                                      s: this.search_input.value
			                                      } );

			// Make request with timeout
			this.#timeout = setTimeout( () => {
				this.request()
				    .then( res => res.json() )
				    .then( data => {
					    if ( data.error ) {
						    console.error( data.error );

						    return;
					    }

					    // Populate with results and show popup
					    this.products_output.innerHTML   = data.products;
					    this.categories_output.innerHTML = data.categories;
					    this.#results_empty              = false;
					    this.searchActive();
				    } )
				    .catch( err => console.error( err ) )
				    .finally( () => this.search_submit_btn.classList.remove( 'loading' ) );
			}, this.#wait_time );
		} );

		this.search_input.addEventListener( 'focus', () => {
			this.#input_focus = true;
			this.searchActive();
		} );

		this.search_input.addEventListener( 'blur', () => {
			this.#input_focus = false;
			this.searchInactive();
		} );

		this.search_results.addEventListener( 'mouseenter', () => {
			this.#popup_hovering = true;
			this.searchActive();
		} );

		this.search_results.addEventListener( 'mouseleave', () => {
			this.#popup_hovering = false;
			this.searchInactive();
		} );
	}

	/**
	 * Make request to the server
	 *
	 * @return {Promise<Response>}
	 */
	async request() {
		return await fetch( this.#url + this.params );
	}

	/**
	 * Compose GET request parameters from object
	 *
	 * @param {Object} parameters {parameter_name: parameter_value}
	 * @return {string} Parameters for GET request
	 */
	composeParameters( parameters ) {
		return Object.entries( parameters ).map( parameter => {
			return `${ parameter[ 0 ] }=${ parameter[ 1 ] }&`;
		} ).join( '' );
	}

	/**
	 * Show search dropdown
	 */
	searchActive() {
		if ( !this.#results_empty ) {
			clearTimeout( this.#hide_popup_timer );

			this.search_results.classList.add( 'show' );
			this.#header_element.classList.add( 'active' );
		}
	}

	/**
	 * Hide search dropdown
	 */
	searchInactive() {
		if ( !this.#popup_hovering && !this.#input_focus ) {
			this.#hide_popup_timer = setTimeout( () => {
				this.search_results.classList.remove( 'show' );
				this.#header_element.classList.remove( 'active' );
			}, this.#hide_popup_timer_wait );
		}
	}
}
