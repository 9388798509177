import { composeParameters, generateId } from './utils';

export default class AddToCart {
	url;
	parameters;
	composed_parameters;
	cart_modal;
	cart_modal_body;
	mini_cart;
	messages;
	message_output;
	#media_under_991;
	#is_active;

	constructor() {
		this.url                 = '/wp-json/custom/add-to-cart';
		this.parameters          = {};
		this.composed_parameters = '';
		this.#media_under_991    = window.matchMedia( '(max-width:991px)' ).matches;
		this.cart_modal          = document.querySelector( '#cart-modal' );
		this.cart_modal_body     = this.cart_modal.firstElementChild;
		this.mini_cart           = document.querySelector( `${ this.#media_under_991 ? '.primary-navigation' : '.secondary-navigation' } .btn--cart` );
		this.messages            = this.cart_modal.querySelector( '[data-messages]' );
		this.messages            = this.messages ? JSON.parse( this.messages.dataset.messages ) : false;
		this.#is_active          = false;
	}

	init() {
		this.addToCartClick();
		this.refreshCart();
		this.removeProductFromCart();

		// Updates response title
		document.documentElement.delegateEventListener( 'click', '.btn--cart', () => {
			const cart_heading = this.cart_modal.querySelector( '.cart__heading' );
			if ( this.messages && cart_heading ) {
				cart_heading.textContent = this.messages.check_cart;
			}
		} );
	}

	/**
	 * Attach click event for adding to cart
	 */
	addToCartClick() {
		document.documentElement.delegateEventListener( 'click', '.btn--add-to-cart', ( e, button ) => {
			// Prevent form from submitting where present
			const form_el = button.closest( 'form' );
			form_el && ( form_el.onsubmit = e => e.preventDefault() );

			if ( this.#is_active ) {
				return;
			}

			// Compose parameters for adding to cart
			this.parameters = {
				product_id: button.dataset.product_id,
				quantity:   button.dataset.quantity || 1
			};
			button.classList.add( 'loading' );

			// Call function for adding to cart
			this.addProductToCart( button );
		} );
	}

	/**
	 * Perform request for adding product to cart
	 * @param {HTMLButtonElement} button
	 */
	addProductToCart( button ) {
		this.composed_parameters = composeParameters( this.parameters );

		fetch( this.url + '?' + this.composed_parameters )
			.then( res => res.json() )
			.then( data => {
				if ( data.error || !data.added_to_cart ) {
					data.error && console.error( data.error );
					this.showNotices( data, 'error' );
					return;
				}

				// Insert new cart data
				this.cart_modal_body.innerHTML = data.layout;

				// Updates response title
				const cart_heading = this.cart_modal.querySelector( '.cart__heading' );
				if ( this.messages && cart_heading ) {
					cart_heading.textContent = this.messages.added_to_cart;
				}

				// Show modal
				this.cart_modal.classList.add( 'show' );

				// Prevent scrolling when modal is opened
				document.body.classList.add( 'overflow' );

				// Show notices if any
				this.showNotices( data );

				// Update mini cart price
				this.updateMiniCart( data );

				// Reset composed parameters
				this.composed_parameters = '';
			} )
			.catch( err => console.error( err ) )
			.finally( () => button.classList.remove( 'loading' ) );
	}

	/**
	 * Perform request for checking cart total
	 * @param parameters
	 */
	refreshCart( parameters = 'refresh-cart=1' ) {
		fetch( this.url + '?' + parameters )
			.then( res => res.json() )
			.then( data => {
				if ( data.error ) {
					console.error( data.error );
					return;
				}

				// Updates mini cart total
				this.updateMiniCart( data );
			} )
			.catch( err => console.error( err ) );
	}

	/**
	 * Updates mini cart total
	 * @param {Object} data
	 */
	updateMiniCart( data ) {
		if ( this.mini_cart ) {
			data.cart_is_empty ? this.mini_cart.classList.add( 'empty' ) : this.mini_cart.classList.remove( 'empty' );

			this.mini_cart.lastElementChild.innerHTML = data.cart_totals;
		}
	}

	/**
	 * Removes product from cart
	 */
	removeProductFromCart() {
		document.documentElement.delegateEventListener( 'click', '.btn--remove', ( e, button ) => {
			button.classList.add( 'loading' );
			button.disabled = true;
			fetch( this.url + `?remove=${ button.dataset.remove }` )
				.then( res => res.json() )
				.then( data => {
					if ( data.error ) {
						console.error( data.error );
						return;
					}

					// Updates cart popup contents
					this.cart_modal_body.innerHTML = data.layout;

					// Updates mini cart totals
					this.updateMiniCart( data );
				} )
				.catch( err => console.error( err ) )
				.finally( () => {
					button.classList.remove( 'loading' );
					button.disabled = false;
				} );
		} );
	}

	/**
	 *
	 * @param data
	 * @param notice_type
	 */
	showNotices( data, notice_type = 'info' ) {
		const notices_wrapper = document.querySelector( '.woocommerce-notices-wrapper' );

		if ( data.notices ) {
			if ( notices_wrapper ) {
				notices_wrapper.innerHTML = data.notices || data;
			} else {
				this.createToast( data.notices || data, notice_type );
			}
		}
	}

	/**
	 * Insert toast element
	 *
	 * @param {String} toast_content
	 * @param toast_type
	 */
	createToast( toast_content, toast_type = 'info' ) {
		const toast = document.createElement( 'div' );
		toast.classList.add( 'toast', 'toast--top', 'toast--wide', `toast--${ toast_type }` );
		toast.id        = generateId();
		toast.innerHTML = `<div class="toast__body">${ toast_content }</div>
			<button type="button" class="btn btn--close toast-close" data-close="#${ toast.id }" aria-controls="#${ toast.id }" aria-expanded="false">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="20" height="20">
			<path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176
			189.28 75.93 89.21 c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28
			32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28
	        12.28-32.19 0-44.48L242.72 256z" /></svg></button>`;

		document.body.appendChild( toast );

		setTimeout( () => toast.classList.add( 'show' ), 300 );

		document.documentElement.delegateEventListener( 'click', '.toast-close', ( e, btn ) => {
			const toast = document.querySelector( btn.dataset.close );
			toast && toast.remove();
		} );
	}
}
