/**
 * Track if element is in viewport with intersection observer
 *
 * @param {HTMLElement} element
 * @param {Function} callback
 * @param {Object} observer_options
 * @param {Object|Array} callback_params
 * @param {boolean} once
 */
export default function inViewport( element, callback, { observer_options = {}, callback_params = {}, once = true } ) {
	const observer = new IntersectionObserver( entries => {
		for ( const entry of entries ) {
			if ( entry.isIntersecting ) {
				const target_el = entry.target;
				callback( { entry, target_el, ...callback_params } );

				once && observer.unobserve( target_el );
			}
		}
	}, observer_options );

	observer.observe( element );
}
